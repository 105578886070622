import axios from '@/axios.js'

export default {
  fetchRoomFeatures ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('room-amenities')
        .then(({data: response}) => {
          commit('SET_ROOM_FEATURES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchRoomFeature (context, roomFeatureId) {
    return new Promise((resolve, reject) => {
      axios.get(`room-amenities/${roomFeatureId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeRoomFeature (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('room-amenities', payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateRoomFeature (context, payload) {
    const id = payload.id;
    delete payload.id;
    return new Promise((resolve, reject) => {
      axios.post(`room-amenities/${id}`, payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyRoomFeature ({ commit }, roomFeatureId) {
    return new Promise((resolve, reject) => {
      axios.delete(`room-amenities/${roomFeatureId}`)
        .then((response) => {
          commit('REMOVE_RECORD', roomFeatureId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
