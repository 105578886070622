import state from './moduleRoomFeatureManagementState.js'
import mutations from './moduleRoomFeatureManagementMutations.js'
import actions from './moduleRoomFeatureManagementActions.js'
import getters from './moduleRoomFeatureManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

