<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Room Feature</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
<!--          <vs-col vs-w="12" class="mb-3">-->
<!--            <span class="mb-2 block text-sm">Category Feature</span>-->
<!--            <v-select-->
<!--              label="name"-->
<!--              :options="featureCategoryOptions"-->
<!--              :reduce="feature => feature.id"-->
<!--              :dir="$vs.rtl ? 'rtl' : 'ltr'"-->
<!--              v-model="payload.feature_category_id"-->
<!--            />-->
<!--          </vs-col>-->

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Room Feature Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

// Store Module
import moduleRoomFeatureManagement from "@/store/room-feature-management/moduleRoomFeatureManagement.js";

export default {
  name: "RoomFeatureAdd",

  metaInfo: {
    title: "Add Room Feature",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  data: () => ({
    payload: {
      name: "",
      order: '99',
    },

    loading: true,

    featureCategoryOptions: []
  }),

  methods: {
    getRoomFeatureCategories() {
      axios
        .get("feature-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            }
          });
        })
        // .catch(err => this.$catchErrorResponse(err));
    },

    getData() {
      if (this.$route.params.id) {
        this.$vs.loading({
          type: 'sound'
        })

        this.$store
          .dispatch("roomFeatureManagement/fetchRoomFeature", this.decryptedID)
          .then(({ data: res }) => {
            this.payload.feature_category_id = res.data.feature_category.id;
            this.payload.name = res.data.name;
          })
          // .catch(err => this.$catchErrorResponse(err))
          .finally(() => {
            this.loading = false
            this.$vs.loading.close()
          })
      }
    },

    storeData() {
      const payload = { ...this.payload };
      payload.order = parseFloat(payload.order);
      let dispatch = "roomFeatureManagement/updateRoomFeature";
      if (!this.$route.params.id) {
        dispatch = "roomFeatureManagement/storeRoomFeature"
      } else {
        payload.id = this.decryptedID;
      }
      this.$store
        .dispatch(dispatch, payload)
        .then((res) => {
          this.$vs.notify({
            title: "Successful",
            text: res.message,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/room-features");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  mounted() {},

  created() {
    if (!moduleRoomFeatureManagement.isRegistered) {
      this.$store.registerModule("roomFeatureManagement", moduleRoomFeatureManagement);
      moduleRoomFeatureManagement.isRegistered = true;
    }

    this.getRoomFeatureCategories()
    this.getData()
  }
};
</script>

<style></style>
